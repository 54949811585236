<template>
  <div>
      <el-card class="mb-5 setting-card-khan" :class="{ dark: false }" v-for="(item, index) in pageList" :key="index">
          <h2 slot="header" class="clearfix mt-0 mb-0">
              {{ item.category }}
          </h2>
          <ul class="settings-list">
              <li v-for="(route, index) in item.items" v-can="route.is_show" @click="onHandler(index)"
                  :key="index">
                  <router-link class="settings-list__item" :to="{ path: `${route.link}` }">
                      <i class="el-icon-s-data icon"></i>
                      <span>
                          {{ $t(route.name) | truncate(20, "...") }}
                      </span>
                  </router-link>
              </li>
          </ul>
      </el-card>
  </div>    
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isActive: 0,
      isCurrentRouter: true,
      pageList: [
        {
          category: "Настройки системные",
          items: [
              {
                  name: 'message.notifications',
                  link: 'systeamNotification',
                  is_show: 'systeamNotification.index'
              },
              {
                  name: 'message.notificationTypes',
                  link: 'notificationTypes',
                  is_show: 'notificationTypes.index'
              },
              {
                  name: 'message.kpTemplates',
                  link: 'kpTemplates',
                  is_show: 'kPExampleTemplates.index'
              },
              {
                  name: 'message.dealStages',
                  link: 'dealStage',
                  is_show: 'dealStages.index'
              },
              {
                  name: 'message.paymentTypes',
                  link: 'paymentTypes',
                  is_show: 'paymentTypes.index'
              },
              {
                  name: 'message.sources',
                  link: 'sources',
                  is_show: 'sources.index'
              },
              {
                  name: 'message.companies',
                  link: 'companies',
                  is_show: 'companies.index'
              },
              {
                  name: 'message.filial',
                  link: 'filial',
                  is_show: 'filials.index'
              },
              {
                  name: 'message.countries',
                  link: 'country',
                  is_show: 'countries.index'
              },
              {
                  name: 'message.cities',
                  link: 'city',
                  is_show: 'cities.index'
              },
              {
                  name: 'message.categories',
                  link: 'category',
                  is_show: 'categories.index'
              },
              {
                  name: 'message.marketplace',
                  link: 'marketplace',
                  is_show: 'marketplace.index'
              },
          ]
        },
        {
        category: "Настройки системные2",
          items: [
              {
                  name: 'message.clientTypes',
                  link: 'clientTypes',
                  is_show: 'clientTypes.index'
              },
              {
                  name: 'message.dealGoals',
                  link: 'dealGoals',
                  is_show: 'dealGoals.index'
              },
              {
                  name: 'message.dealConditions',
                  link: 'dealConditions',
                  is_show: 'dealConditions.index'
              },
              {
                  name: 'message.shipmentTerms',
                  link: 'shipmentTerms',
                  is_show: 'shipmentTerms.index'
              },
              {
                  name: 'message.colors',
                  link: 'colors',
                  is_show: 'colors.index'
              },
              {
                  name: 'message.packages',
                  link: 'packages',
                  is_show: 'packages.index'
              },
              {
                  name: 'message.typeOfProjectWorks',
                  link: 'typeOfProjectWorks',
                  is_show: 'typeOfProjectWorks.index'
              },
              {
                  name: 'message.managerTypes',
                  link: 'managerTypes',
                  is_show: 'managerTypes.index'
              }
          ]
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      mode: "MODE"
    }),
  },
  methods: {
    onHandler(index) {
      this.isActive = index;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  gap: 18px;

  // settings-list
  &-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;

    // settings-list__item
    &__item {
      width: 100%;
      height: 100%;
      padding: 1rem 12px;
      border-radius: 5px;
      border: 1px solid #d1d5db;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      text-align: center;
      color: #92929f;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      span {
        font-size: 16px;
      }

      .icon {
        color: #92929f;
        font-size: 2rem;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        color: #60a5fa;
        background-color: rgba($color: #fff, $alpha: 0.4);
        border-color: #60a5fa;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .icon {
          color: #60a5fa;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.settings {
  .el-card {
    width: 100%;

    // el-card__header
    &__header {
      margin: 0;
      padding: 12px 20px;

      h2 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #212121a4;
        margin: 0;
      }
    }

    &.dark {
      background-color: #151e34c2 !important;
      border-color: #44587c75 !important;

      h2 {
        color: #fff;
      }

      .el-card__header {
        border-color: #44587c75;
      }

      .settings-list__item {
        border-color: #44587c75;
        background-color: #2a2a3fa6;

        &:hover {
          color: #fff;
          border-color: #fff;

          .icon {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.d-modal-style .setting-card-khan.el-card{
    background-color: #151e34c2 !important;
    border-color: #44587c75 !important;
}
.d-modal-style .setting-card-khan .settings-list__item,
.d-modal-style .setting-card-khan .el-card__header {
    border-color: #44587c75 !important;
}
</style>
